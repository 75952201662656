.container-login{
    background-color: #fff;
}

.container-center{
    position: sticky;
    width: -moz-fit-content;
    width: fit-content;
    margin-top: 214px;
    opacity: 1;
    transition: 1s cubic-bezier(.001,1,.999,1);
    z-index: 150;
    display: flex;
    /* padding-bottom: 10px; */
    left: 40%;
    /* background-image: url(../../assets/bg1.jpg); */
}

.btn-close-login{
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 3;
    cursor: pointer;
    color: #fff;
}

.login {
    background: hsl(0deg 0% 100% / 97%);
    box-shadow: 0 10px 15px rgb(0 0 0 / 25%);
    border-radius: 20px;
    padding: 30px 30px;
    width: 370px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.logo-area {
    height: 46px;
    display: flex;
    justify-content: center;
    background-color: #01346b;
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 2px 0px 2px 2px rgb(0 0 0 / 24%);
}

.logo-area img {
    padding: 20px;
    width: 170px;
    height:130px;

}

form{
    margin-top: 1.5em;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.logo-area h1 {
    text-align: center;
    margin-bottom: 0.5em;
    color: #ffffff;
    font-weight: 500;
}

form input {
    margin-bottom: 15px;
    height: 35px;
    border: 0;
    border-radius: 7px;
    padding: 10px;
    font-size: 15px;
    background-color: #fff;
    color: #000;
}

form button {
    height: 35px;
    border: 0;
    border-radius: 7px;
    background-color: #01346b;
    color: #fff;
    font-size: 1.3em;
}

.login a {
    margin:1.5em 0;
    color: #01346b;
    cursor: pointer;
}

.login p{
    margin: 1.5em 0;
    color: #01346b;
    cursor: pointer;
    text-align: center;
}

.info{
    display: flex;
    width: 100%;
    justify-content: center;
}


