$body-bg: #0f0f0f;
$main-color: #5600ff;
$txt-color: #fff;

$white: #fff;
$black: #000;
$green: #4caf50;
$greenT: #4caf50bd;
$red: #e91e63;
$redT: #e91e63bd;
$Yellow: #d9c300;
$YellowT: #d9c300ab;



$box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

$header-height: 8rem;
$header-shrink-height: 5rem;
$border-radius: 30px;

$mobile-width: 600px;
$tablet-width: 1024px;

$font-family: "Montserrat", sans-serif;
